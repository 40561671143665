import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/pages";
import { initElementComponents } from "@/libs/element-init";
import iconPlugin from "@/components/Icon";
import "element-plus/dist/index.css";
import "@/assets/iconfont/iconfont.css";

const app = createApp(App);
initElementComponents(app, "page");
app.use(iconPlugin);
app.use(router).mount("#app");
